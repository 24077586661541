"use client";

// next
import Image from "next/image";
import { useState } from "react";

// third party
import cx from "classnames";

// private components
import { steps } from "./steps";

// styles
import styles from "./index.module.scss";

export default function StepSwitcher() {
  const [selectedStep, setSelectedStep] = useState<number>(0);

  return (
    <div className={cx(styles.container, "row mx-0")}>
      <div className="col-12 col-lg-6 col-xl-5 d-flex flex-column">
        {steps.map((step, i) => (
          <div
            className={cx(styles.step, { [styles.active]: i === selectedStep })}
            onClick={() => setSelectedStep(i)}
            key={i}
          >
            <span className="text-style-h2 text-center pe-4 m-0">{i + 1}</span>
            <h4 className="text-style-h5">{step.title}</h4>
            <p className="text-style-body1-regular m-0 mt-3 d-lg-none">{step.description}</p>
          </div>
        ))}
      </div>
      <div className={cx(styles.image, "col-lg-6 col-xl-5 d-none d-lg-flex")}>
        <p className="text-style-body1-regular m-0">{steps[selectedStep].description}</p>
        <Image
          src={steps[selectedStep].image}
          alt={`Step Number ${selectedStep + 1}`}
          width={450}
          height={300}
          draggable={false}
        />
      </div>
    </div>
  );
}
