"use client";

// react
import { useState } from "react";

// third party
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";

// anivive
import { useInterval } from "@anivive/hooks";

export default function LoaderComponent({
  className,
  altText,
}: {
  className?: string;
  altText?: string;
}): JSX.Element {
  const [load, setLoad] = useState<number>(0);

  useInterval(() => {
    if (load === 0) {
      setLoad(1);
    }
    if (load === 1) {
      setLoad(2);
    }
    if (load === 2) {
      setLoad(3);
    }
    if (load === 3) {
      setLoad(0);
    }
  }, 300);

  return (
    <div className={cx("d-flex justify-content-center align-items-center", className)}>
      <FontAwesomeIcon icon={faSpinner} pulse />
      <p className="ms-3 my-0" style={{ color: "inherit" }}>
        {altText ? altText : "Loading"}
        <span className={cx({ invisible: load === 0 })}>.</span>
        <span className={cx({ invisible: load <= 1 })}>.</span>
        <span className={cx({ invisible: load !== 3 })}>.</span>
      </p>
    </div>
  );
}
