"use client";

// next
// import Image from "next/image";
import { FormEvent, useState, useMemo } from "react";

// third party
import { faLocationDot } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";

// components
import GoogleMap from "@/components/ui/google-map";
import PaginationButtons from "@/components/ui/pagination-buttons";

// types
import { Practice } from "@/typedef/trial";

// parent styles
import sectionStyles from "../section.module.scss";

// private methods
import zipSearch from "./use-zip-search";

// styles
import styles from "./index.module.scss";

function chunk<T>(arr: T[], size: number) {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size),
  );
}

export default function FindStudySiteSection({
  initialLocations,
}: {
  initialLocations: Practice[];
}) {
  const [page, setPage] = useState(1);
  const [zip, setZip] = useState("");
  const [locations, setLocations] = useState<Practice[]>(initialLocations);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await zipSearch({ zip: Number(zip) })
      .then(({ data }) => setLocations(data))
      .catch(() => null);
  };

  const paginatedLocations = useMemo(() => {
    return chunk(locations, 6);
  }, [locations]);

  return (
    <section className={cx(sectionStyles.section, styles["find-studies"], "row mx-0 px-0")}>
      <h2 className="text-style-h5 mb-4 text-center">Find a study site near you</h2>
      <h3 className="text-style-h4 mb-4 text-center">
        Discover ground breaking possibilities in veterinary medicine.
      </h3>
      <form
        onSubmit={(e) => handleSubmit(e)}
        className="d-flex justify-content-center align-items-center mb-5"
      >
        <div className="d-flex align-items-center">
          <input
            className="text-style-body1-regular"
            name="zip"
            type="text"
            placeholder="Zip Code"
            onChange={({ target: { value } }) => setZip(value)}
            autoComplete="postal-code"
          />
          <FontAwesomeIcon icon={faLocationDot} className={styles.icon} />
        </div>
        <button
          className={cx("primary text-style-body1-bold ms-3", { disabled: zip === "" })}
          disabled={zip === ""}
        >
          Submit
        </button>
      </form>
      <div className={cx(styles["map-container"], "mb-5")}>
        <GoogleMap
          maxZoom={4}
          fitMarkers
          id="home-map"
          markers={locations?.map(({ address: { latitude, longitude } }) => ({
            latitude,
            longitude,
          }))}
        />
      </div>
      <div className={cx(styles["card-container"], "px-3 px-sm-4")}>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h3 className="text-style-h5">Study Sites Nearby</h3>
          <PaginationButtons
            nextDisabled={page === paginatedLocations.length || paginatedLocations.length === 0}
            prevDisabled={page <= 1}
            onNext={() => setPage(page + 1)}
            onPrev={() => setPage(page - 1)}
          />
        </div>
        <div className={paginatedLocations.length ? styles.cards : undefined}>
          {paginatedLocations.length ? (
            paginatedLocations[page - 1].map((location, i) => (
              <article className={styles.card} key={i}>
                <dl className="m-0">
                  <dt className="text-style-h6 clamp--2 mb-3">{location.name}</dt>
                  <dd className="d-flex flex-column">
                    <div className="clamp--2">{location.address.addressOne}</div>
                    <div className="d-flex align-items-center flex-wrap mt-2">
                      <div className="flex-shrink-0">{location.phone}</div>
                      <span className="mx-3">&#x2022;</span>
                      <div className="flex-shrink-0">
                        {location.address.distanceFromHospitalInMile} miles
                      </div>
                    </div>
                  </dd>
                </dl>
                {/* <Image
                src="https://cdn.anivive.com/image/upload/v1689201146/events/photo-1689126437563-f5d936a06713.jpg"
                alt=""
                width={160}
                height={160}
                draggable={false}
              /> */}
              </article>
            ))
          ) : (
            <div className="d-flex justify-content-center w-100 mt-5">
              <p className="text-style-body1-regular">
                No studies within 2000 miles of your zip code
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
