"use client";
// third party
import { ReactNode } from "react";

import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";

// private
import styles from "./index.module.scss";

export default function PaginationButtons({
  children,
  nextDisabled,
  prevDisabled,
  onNext,
  onPrev,
}: {
  children?: ReactNode;
  nextDisabled: boolean;
  prevDisabled: boolean;
  onNext: () => void;
  onPrev: () => void;
}) {
  return (
    <div className="d-flex align-items-center">
      {children}
      <div
        className={cx(styles["change-page"], "me-2", {
          [styles.disabled]: prevDisabled,
        })}
        onClick={() => {
          if (!prevDisabled) {
            onPrev();
          }
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
      <div
        className={cx(styles["change-page"], "ms-2", {
          [styles.disabled]: nextDisabled,
        })}
        onClick={() => {
          if (!nextDisabled) {
            onNext();
          }
        }}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </div>
  );
}
