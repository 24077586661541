"use client";

// next
import Link from "next/link";
import { useState } from "react";

// third party
import { faEnvelope } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";

// components
import LoaderComponent from "@/components/ui/loader-component";

// services
import { addListMember } from "@/services/actions/mailchimp";

// styles
import styles from "./index.module.scss";

export default function NewsletterForm() {
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const disableForm = !pattern.test(email) || loading;

  const handleSubmit = async () => {
    setLoading(true);
    setSuccess(false);
    setError(null);
    if (!disableForm) {
      await addListMember({
        listId: "52c86b7ad8",
        email,
      }).then((response) => {
        if (response.id) {
          setSuccess(true);
        } else {
          if (response === "Invalid Resource") {
            setError("Email was not accepted.");
          } else if (response === "Member Exists") {
            setError("Email is already in use.");
          } else {
            setError("Something went wrong.");
          }
        }
        setLoading(false);
      });
    }
  };

  return (
    <div className={cx(styles.form, "text-center text-lg-start")}>
      {success ? (
        <>
          <h2 className="text-style-h2 mb-4">
            Thank you for <br /> subscribing.
          </h2>
          <p className="text-style-body1-regular">
            You&apos;re now subscribed to our newsletter. Don&apos;t <br /> forget to check out the
            latest <Link href="/studies">case studies</Link>.
          </p>
        </>
      ) : (
        <>
          <h2 className="text-style-h6">Join our network</h2>
          <h3 className="text-style-h2 my-4">
            Get notified
            <br />
            about new studies
          </h3>
          <form onSubmit={() => handleSubmit()} className="d-flex flex-column">
            {/* eslint-disable-next-line max-len */}
            <div className="d-flex flex-column flex-sm-row mx-0 justify-content-center justify-content-xl-start align-items-center">
              <div
                className={cx(styles.input, "d-flex align-items-center position-relative w-100")}
              >
                <input
                  className="text-style-body1-regular w-100"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="Enter your email address"
                  onChange={({ target: { value } }) => setEmail(value)}
                />
                <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />
              </div>
              <button
                className={cx(
                  { disabled: disableForm },
                  "primary text-style-body1-bold mt-3 mt-sm-0 ms-sm-3",
                )}
                type="button"
                onClick={() => handleSubmit()}
                disabled={disableForm}
              >
                {loading ? <LoaderComponent /> : "Notify Me"}
              </button>
            </div>
            {error && <p className={cx(styles.error, "text-style-body1-bold mt-3")}>{error}</p>}
          </form>
        </>
      )}
    </div>
  );
}
