import dynamic from "next/dynamic";
import { ReactElement } from "react";

const BasicMap = dynamic(() => import("./basic"), { ssr: false });
const AdvancedMap = dynamic(() => import("./advanced"), { ssr: false });

type Props = {
  id: string;
  markers?: {
    latitude: number;
    info?: ReactElement;
    markerId?: string;
    details?: React.ReactNode;
    longitude: number;
  }[];
  fitMarkers?: boolean;
  advanced?: boolean;
  maxZoom?: number;
};

export default function GoogleMap({ advanced, ...props }: Props) {
  if (advanced) {
    return <AdvancedMap {...props} />;
  }
  return <BasicMap {...props} />;
}
