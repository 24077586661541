"use client";

// next
import { ReactNode, useRef, useState } from "react";

// third party
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";

// anivive
import { useBreakpoints, useTimeout } from "@anivive/hooks";

// private
import styles from "./index.module.scss";

export default function CollapsibleContainer({
  head,
  body,
  defaultOpen = false,
  className,
  hasIcon,
}: {
  head: ReactNode;
  body: ReactNode;
  defaultOpen?: boolean;
  className?: string;
  hasIcon?: boolean;
}) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(defaultOpen);
  const [ready, setReady] = useState(false);
  const { current } = useBreakpoints();

  // TODO: see if there is a way to rotate the arrow, maybe pass through {head}

  useTimeout(() => {
    setReady(true);
  }, 100);

  return (
    <div
      className={cx(styles.card, className, {
        [styles.transition]: ref.current && ready && ref.current?.clientHeight,
      })}
      style={
        !open
          ? { paddingBottom: "0" }
          : { paddingBottom: `${(!!current && ref.current?.clientHeight) || "0"}px` }
      }
    >
      <div className={styles.head} onClick={() => setOpen(!open)}>
        {head}
        {hasIcon && (
          <FontAwesomeIcon
            icon={faChevronDown}
            className={cx(styles.arrow, { [styles.flipped]: open })}
          />
        )}
      </div>
      <div className={styles.body} ref={ref}>
        {body}
      </div>
    </div>
  );
}
