export const steps = [
  {
    number: 1,
    title: "Publication and Promotion",
    image: "/trial/recruitment-step-1",
    description:
      // eslint-disable-next-line max-len
      "A clinical study is published and promoted on TRIAL Recruit, both online and through our network of veterinarians.",
  },
  {
    number: 2,
    title: "Application and Screening",
    image: "/trial/recruitment-step-2",
    description:
      // eslint-disable-next-line max-len
      "New patients can apply for the study through pet parents or veterinarians, and our recruitment coordinator contacts them within 24 hours for information, screening, and collects additional details for the pre-screening process.",
  },
  {
    number: 3,
    title: "Scheduling the Clinic Visit",
    image: "/trial/recruitment-step-3",
    description:
      // eslint-disable-next-line max-len
      "The recruitment coordinator collaborates with the trial site and pre-screened pet owners to schedule an in-clinic visit.",
  },
  {
    number: 4,
    title: "Screening and Swag Bag",
    image: "/trial/recruitment-step-4",
    description:
      // eslint-disable-next-line max-len
      "The coordinator ensures the pet owner is prepared for the screening visit, and regardless of enrollment, they receive a mailed swag bag as a token of appreciation for their participation in pet health.",
  },
];
